//a���� ����`���������`��
$(function(){
  $('a[href^="#"]').click(function(){
    var speed = 500;
    var href= $(this).attr("href");
    var target = $(href == "#" || href == "" ? 'html' : href);
    var position = target.offset().top;

    position -= $('.l-header').innerHeight();

    if($('u-sp').css('display') != 'none') {
      position -= $('.p-sideSp').innerHeight();
    }

    $("html, body").animate({scrollTop:position}, speed, "swing");
    return false;
  });
});

const showFlashMessage = function(val) {
  $('.p-flashMessage').addClass('is-show');
  if(val) {
    $('.p-flashMessage__txt').html(val);
  }
};

$(function(){
  const $side = $('.l-side');

  if($side[0]) {
    const $header = $('.l-header');

    $(window).on('scroll', function(event) {
      run();
    });

    const run = function() {
      var maxT = $(window).scrollTop();
      var tarT = $side.offset().top;
      var gap = 82;

      if($('.l-body.-home')[0]) {
        gap = 48;
      }

      if(maxT > tarT - gap) {
        $side.addClass('is-fix');
      } else {
        $side.removeClass('is-fix');
      }
    }
    run();
  }
});

$(function(){
  $('.remodal').remodal({ hashTracking: false });
});

$(function(){
  const $btn = $('.about-faq__conBtn a');

  if($btn[0]) {
    const $con = $('.about-faq__con');

    $btn.click(function(event) {
      $(this).toggleClass('is-on');
      $con.stop().slideToggle();
    });
  }
});

$(function(){
  const $body = $('.l-body');
  if($body[0] && $body.hasClass('-home')) {
    const $ani = $('.u-aniFadeOut');
    let isSc = false;

    $(window).on('scroll', function(event) {
      run();
    });

    const run = function() {
      if($(window).scrollTop() > 10) {
        if(!isSc) {
          isSc = true;

          $ani.addClass('is-ani');

          setTimeout(function(){
            $body.addClass('is-sc');
          }, 200);

          setTimeout(function(){
            $ani.removeClass('is-ani');
          }, 400);
        }
      } else {
        if(isSc) {
          isSc = false;

          $ani.addClass('is-ani');

          setTimeout(function(){
            $body.removeClass('is-sc');
          }, 200);

          setTimeout(function(){
            $ani.removeClass('is-ani');
          }, 400);
        }
      }
    }

    run();
  }
});

$(function(){
  const $parents = $('.p-hd__navLink.-parent');

  $parents.each(function(index, el) {
    const $parent = $(this);
    const $child = $parent.next('ul');

    $parent.on('click', function(event) {
      if(!$parent.hasClass('is-cr')) {
        $('.p-hd__navList.-child').stop().slideUp(300);

        $parents.removeClass('is-cr');
        $parents.not($parent).next('ul').stop().slideUp(300);

        $parent.addClass('is-cr');
        $child.stop().slideDown(300);
      } else {
        $parents.removeClass('is-cr');
        $parents.next('ul').stop().slideUp(300);
      }
    });
  });
});

$(function(){
  const $parents = $('.p-side__navLink.-parent');

  $parents.each(function(index, el) {
    const $parent = $(this);
    const $child = $parent.next('ul');

    $parent.on('click', function(event) {
      console.log('ok');

      if(!$parent.hasClass('is-cr')) {
        $parents.removeClass('is-cr');
        $parents.not($parent).next('ul').stop().slideUp(300);

        $parent.addClass('is-cr');
        $child.stop().slideDown(300);
      } else {
        $parents.removeClass('is-cr');
        $parents.next('ul').stop().slideUp(300);
      }
    });
  });
});

$(function(){
  const $parents = $('.p-side2__navLink.-parent');

  $parents.each(function(index, el) {
    const $parent = $(this);
    const $child = $parent.next('ul');

    $parent.on('click', function(event) {
      if(!$parent.hasClass('is-cr')) {
        $parents.removeClass('is-cr');
        $parents.not($parent).next('ul').stop().slideUp(300);

        $parent.addClass('is-cr');
        $child.stop().slideDown(300);
      } else {
        $parents.removeClass('is-cr');
        $parents.next('ul').stop().slideUp(300);
      }
    });
  });
});

$(function(){
  const $menuSpLink = $('.js-menuSpLink');

  if($menuSpLink[0]) {
    const $sideSpLink = $('.js-sideSpLink');
    const $sideNavGroup = $('.js-sideNavGroup');
    const $menuConSp = $('.js-menuConSp');
    const $side = $('.js-side');
    let isShow = false;

    $menuSpLink.on('click', function(event) {
      if(!isShow) {
        changeSideNavGroup(4);
        showMenuConSp();
      } else {
        changeSideNavGroup(0);
        hideMenuConSp();
      }
    });

    $sideSpLink.on('click', function(event) {
      const t = $(this);

      changeSideNavGroup(t.attr('data-num'));
//      console.log(t.attr('data-num'));

      if(!isShow) {
        showMenuConSp();
      } else {
      }
    });

    const showMenuConSp = () => {
      if(!isShow) {
        isShow = true;

        $menuSpLink.addClass('is-on');
        $menuConSp.addClass('is-show');
        $side.addClass('is-show');
      }
    }

    const hideMenuConSp = () => {
      if(isShow) {
        isShow = false;

        $menuSpLink.removeClass('is-on');
        $menuConSp.removeClass('is-show');
        $side.removeClass('is-show');
      }
    }

    const changeSideNavGroup = (num) => {
      $sideSpLink.removeClass('is-cr');
      $sideNavGroup.removeClass('is-cr');

      if(num > 0) {
        $sideSpLink.filter('[data-num="' + num + '"]').addClass('is-cr');
        $sideNavGroup.filter('[data-num="' + num + '"]').addClass('is-cr');
      }
    }
  }
});

$(function(){
  $('.js-kvSlider').each(function(index, el) {
    const $recomSlider = $(this);

    $recomSlider.slick({
//      infinite: false,
      dots: true,
      variableWidth: true
    });
  });
});

$(function(){
  $('.js-eventSlider').each(function(index, el) {
    const $recomSlider = $(this);

    $recomSlider.slick({
      infinite: false,
      dots: true,
      variableWidth: true
    });
  });
});

$(function(){
  $('.about-voiceCardsSlider__slick').each(function(index, el) {
    const $slider = $(this);

    $slider.slick({
      infinite: false,
      dots: true,
      variableWidth: true,
      adaptiveHeight: true
    });
  });
});

$(function(){
    function sliderSetting(){
        var width = $(window).width();

        if(width < 1100){
            $('.js-magazineSlider').not('.slick-initialized').slick({
              arrows: false,
              dots: true
            });
        } else {
            $('.js-magazineSlider.slick-initialized').slick('unslick');
        }
    }

    sliderSetting();

    $(window).resize( function() {
        sliderSetting();
    });
});

$(function(){
  $('.js-recomSlider').each(function(index, el) {
    const $recomSlider = $(this);

    $recomSlider.on('beforeChange', function(event, slick, currentSlide, nextSlide){
      $recomSlider.addClass('is-next');
    });

    $recomSlider.slick({
      dots: true,
      variableWidth: true
    });
  });
});

$(function(){
  $('.js-schoolDetailkvSlider').each(function(index, el) {
    const $recomSlider = $(this);

    $recomSlider.slick({
      dots: true,
      arrows: false
    });
  });
});

$(function(){
  const $tabNav = $('.js-schoolDetail-tabNav a');

  if($tabNav[0]) {
    const $tabNav2 = $('.js-schoolDetail-tabNav2 a');
    const $tabCon = $('.js-schoolDetail-tabCon');

    $tabNav.click(function(event) {
      changeTab($(this).attr('data-num'));
    });

    $tabNav2.click(function(event) {
      changeTab($(this).attr('data-num'));
    });

    function changeTab(num) {
      $tabNav.removeClass('is-cr').eq(num - 1).addClass('is-cr');
      $tabNav2.removeClass('is-cr').eq(num - 1).addClass('is-cr');
      $tabCon.hide().eq(num - 1).stop().fadeIn(400);
    }

    changeTab(1);
  }
});

$(function(){
  $('.js-schoolDetail-tabCon__slider').each(function(index, el) {
    const $recomSlider = $(this);

    $recomSlider.slick({
      dots: true,
      arrows: false
    });
  });
});

$(function(){
  $('.home-kv__memberBtn').each(function(index, el) {
    const $btn = $(this);

    $(window).on('scroll', function(event) {
      run();
    });

    run();

    function run() {
      if($(window).scrollTop() + $(window).innerHeight() > $('.l-footer').offset().top) {
        $btn.addClass('is-hide');
      } else {
        $btn.removeClass('is-hide');
      }
    }
  });
});

$(function(){
  $('.school-results').each(function(index, el) {
    const tar = $(this);
    const body = $('.l-body');
    const bg = $('.l-mainWrap__bg');
    const headerWrap = $('.l-header__wrap');

    body.addClass('-bgGr');

    $(window).on('resize', function(event) {
      run();
    });

    const run = function() {
      bg.css('top', tar.offset().top - headerWrap.innerHeight());
    }

    run();
  });
});

$(function(){
  const btn = $('.school-search__mapBtn');

  if(btn[0]) {
    const area = $('.school-search__area');
    const close = $('.school-search__areaClose');

    btn.each(function(index, el) {
      $(this).click(function(event) {
        if($(this).hasClass('is-on')) {
          runClose();
        } else {
          runOpen(index);
        }
      });
    });

    close.click(function(event) {
      runClose();
    });

    const runOpen = function(index) {
      btn.removeClass('is-on').eq(index).addClass('is-on');
      area.removeClass('is-show').eq(index).addClass('is-show');
    }

    const runClose = function() {
      btn.removeClass('is-on');
      area.removeClass('is-show');
    }

    area.each(function(index, el) {
      const t = $(this);

      t.find('input[type="checkbox"]').each(function(index2, el2) {
        const t2 = $(this);

        t2.on('change', function(event) {
          btn.eq(index).removeClass('is-sel');

          if(t2.filter(':checked').length > 0) {
            btn.eq(index).addClass('is-sel');
          }
        });
      });
    });
  }
});

$(function(){
  const $sc = $('.js-scheduleSc');

  if($sc[0]) {
    const $wrap = $('.js-scheduleScWrap');
    const $prev = $('.js-scheduleScPrev');
    const $next = $('.js-scheduleScNext');
    const moveX = 160;
    let isSc = false;

    $(window).on('resize', function(event) {
      runCheck();
    });

    $prev.on('click', function(event) {
      runScX(moveX * -1);
    });

    $next.on('click', function(event) {
      runScX(moveX);
    });

    const runCheck = function() {
//      console.log($wrap.innerWidth() , $sc.innerWidth());
      if($wrap.innerWidth() <= $sc.innerWidth()) {
        if(!isSc) {
          isSc = true;

          $prev.removeClass('is-off');
          $next.removeClass('is-off');
        }
      } else {
        if(isSc) {
          isSc = false;

          $prev.addClass('is-off');
          $next.addClass('is-off');
        }
      }
    };
    runCheck();

    const runScX = function(value) {
      $wrap.stop().animate({
        scrollLeft: $wrap.scrollLeft() + value
        },
        300, function() {
      });
    };
  }
});

$(function(){
  $('.js-scheduleSpSlider').each(function(index, el) {
    const $recomSlider = $(this);

    $recomSlider.slick({
      dots: true
    });

    $(window).on('resize', function(event) {
      $recomSlider.slick('setPosition');
    });
  });
});

$(function(){
  const week = $('.js-schedule-week');

  if(week[0]) {
    let now = 1;

    // URL�Υѥ��`����ȡ��
    var urlParam = location.hash.substring(1);

    // URL�˥ѥ��`�������ڤ������
    if(urlParam) {
      // ��&�������ޤ�Ƥ�����Ϥϡ�&���Ƿָ�
      var param = urlParam.split('&');

      // �ѥ��`�����{�����ä����Ф�����
      var paramArray = [];

      // ���⤷�����Ф˥ѥ��`�����{
      for (i = 0; i < param.length; i++) {
        var paramItem = param[i].split('=');
        paramArray[paramItem[0]] = paramItem[1];
      }

      // �ѥ��`��id��osaka���ɤ������жϤ���
      if (paramArray.w) {
        now = paramArray.w;
      }
    }

    week.eq(now - 1).show();

    $('.js-schedule-week-prev').click(function(event) {
      now --;
      if(now < 1) now = week.length;

      week.hide().eq(now - 1).stop().fadeIn();
    });

    $('.js-schedule-week-next').click(function(event) {
      now ++;
      if(now > week.length) now = 1;

      week.hide().eq(now - 1).stop().fadeIn();
    });
  }
});

$(function(){
  $('.js-scheduleSpSliderDay').each(function(index, el) {
    const $recomSlider = $(this);
    const $day = $('.js-scheduleSpSliderDay-day');

    $recomSlider.on('afterChange', function(event, slick, currentSlide, nextSlide){
      let d = $('.slick-current .schedule-week__boxDate').html();
      $day.html(d);
    });

    let now = 1;

    // URL�Υѥ��`����ȡ��
    var urlParam = location.hash.substring(1);

    // URL�˥ѥ��`�������ڤ������
    if(urlParam) {
      // ��&�������ޤ�Ƥ�����Ϥϡ�&���Ƿָ�
      var param = urlParam.split('&');

      // �ѥ��`�����{�����ä����Ф�����
      var paramArray = [];

      // ���⤷�����Ф˥ѥ��`�����{
      for (i = 0; i < param.length; i++) {
        var paramItem = param[i].split('=');
        paramArray[paramItem[0]] = paramItem[1];
      }

      // �ѥ��`��id��osaka���ɤ������жϤ���
      if (paramArray.d) {
        now = paramArray.d;
        $day.html(now);
      }
    }

    $recomSlider.slick({
//      dots: true
      initialSlide: now - 1
    });

    $(window).on('resize', function(event) {
      $recomSlider.slick('setPosition');
    });
  });
});

$(function(){
  $('.js-slick-prev').each(function(index, el) {
    $(this).click(function(event) {
      $('.slick-prev').trigger('click');
    });
  });

  $('.js-slick-next').each(function(index, el) {
    $(this).click(function(event) {
      $('.slick-next').trigger('click');
    });
  });
});

$(function(){
  const form = $('.p-form');

  if(form[0]) {
    const $type = $('.js-inputType');

    const $motivationRow = $('.js-inputMotivationRow');

    const $jobRow = $('.js-inputJobRow');
    const $job = $('.js-inputJob');
    const $jobEtcRow = $('.js-inputJobEtcRow');

    const $schoolTypeRow = $('.js-inputSchoolTypeRow');
    const $schoolType = $('.js-inputSchoolType');
    const $schoolTypeEtcRow = $('.js-inputSchoolTypeEtcRow');

    const $belongSchoolRow = $('.js-inputBelongSchoolRow');
    const $belongSchoolAddrRow = $('.js-inputBelongSchoolAddrRow');

    setInterval(function(){
      run();
    }, 100);

    const run = function() {
      const selType = $type.val();

      if(selType == '60' || selType == '70') {
        $jobRow.removeClass('is-hide');

        if($job.val() == '60') {
          $jobEtcRow.removeClass('is-hide');
        } else {
          $jobEtcRow.addClass('is-hide');
        }
      } else {
        $jobRow.addClass('is-hide');
        $jobEtcRow.addClass('is-hide');
      }

      if(selType == '20' || selType == '30') {
        $motivationRow.removeClass('is-hide');
        $schoolTypeRow.removeClass('is-hide');

        if($schoolType.val() == '90') {
          $schoolTypeEtcRow.removeClass('is-hide');
        } else {
          $schoolTypeEtcRow.addClass('is-hide');
        }

        $belongSchoolRow.removeClass('is-hide');
        $belongSchoolAddrRow.removeClass('is-hide');
      } else {
        $motivationRow.addClass('is-hide');
        $schoolTypeRow.addClass('is-hide');
        $schoolTypeEtcRow.addClass('is-hide');
        $belongSchoolRow.addClass('is-hide');
        $belongSchoolAddrRow.addClass('is-hide');
      }
    };
  }
});

$(function(){
  const $con = $('.mypage-form__tabCon');

  if($con[0]) {
    const $btn = $('.js-mypageTabBtn');

    $btn.on('click', function(event) {
      const index = $btn.index($(this));

      $btn.removeClass('is-cr').eq(index).addClass('is-cr');
      $con.removeClass('is-cr').eq(index).addClass('is-cr');
    });
  }
});

$(function(){
  const $btn = $('.js-schedule-name-btn');

  if($btn[0]) {
    const $step1 = $('.js-schedule-name-step1');
    const $step2 = $('.js-schedule-name-step2');
    let nowStep = 0;

    $btn.on('click', function(event) {
      if(nowStep == 0) {
        nowStep = 1;

        $step1.hide();
        $step2.stop().fadeIn();
      } else if(nowStep == 1) {
        nowStep = 0;

        $step2.hide();
        $step1.stop().fadeIn();
      }
    });
  }
});

$(function(){
  const $week = $('.schedule-week');

  if($week[0]) {
    const $con = $('.schedule-con');
    const $headerWrap = $('.l-header__wrap');
    const $head = $('.schedule-head');
    const $tags = $('.schedule-tags');
    const $ttl2 = $('.schedule-ttl2__txt');
    const $en = $('.schedule-week__boxEn');
    const $date = $('.schedule-week__boxDate');
    const $box = $('.schedule-week__box');

    const $fixBg = $('<div class="schedule-fixBg"></div>');
    $con.prepend($fixBg);

    $en.wrapInner('<span></span>');
    const $enInner = $en.find('span');

    let isFix = false;

    $(window).on('scroll', function(event) {
      run();
    });

    setInterval(function(){
      run();
    }, 100);

    const run = function() {
      let winT = $(window).scrollTop();
      let conT = $con.offset().top;
      let headerWrapH = $headerWrap.innerHeight();
      let gap = winT - conT + headerWrapH + 30;

      if(gap > 0) {
        if($week.css('display') != 'none') {
          addFix(gap);
        } else {
          if(isFix) {
            $("html, body").animate({scrollTop:0}, 0, "swing");
          }
          removeFix();
        }
      } else {
        removeFix();
      }
    }

    const addFix = function(gap) {
      return false; //ֹͣ

      if(!isFix) {
        isFix = true;
      }

      $head.css({top: gap});
      $tags.css({top: gap});
      $ttl2.css({top: gap});
      $enInner.css({top: gap});
      $date.css({top: gap});

      let h = $('.schedule-week__box:visible').eq(0).offset().top - $con.offset().top;
      let sliderDateH = $('.js-scheduleSpSlider:visible').find('.schedule-week__boxDate').eq(0).innerHeight();

      if(sliderDateH) {
        h -= sliderDateH;
      }

      $fixBg.css({
        top: gap - 200,
        height: h + 200
      });
    }

    const removeFix = function() {
      return false; //ֹͣ

      if(isFix) {
        isFix = false;

        $head.removeAttr('style');
        $tags.removeAttr('style');
        $ttl2.removeAttr('style');
        $enInner.removeAttr('style');
        $date.removeAttr('style');
        $fixBg.removeAttr('style');
      }
    }

    run();

    const initNine = function() {
      const $tar = $('.schedule-week__times li').eq(9);

      let timter;
      let timerCnt = 0;
      let t = $tar.offset().top;
      let tagsH = $tags.innerHeight();

      t -= 330;

      if(tagsH) {
        t -= tagsH;
      }

      if($('.u-sp').css('display') != 'none') {
        t += 130;
      }

      timer = setInterval(function(){
        $("html, body").animate({scrollTop:t}, 0, "swing");
        timerCnt ++;
        if(timerCnt > 15) {
          clearInterval(timer);
        }
      }, 50);
    };
    initNine();
  }
});

$(function(){
  $('.p-tab__btns').each(function(index, el) {
    const $btns = $(this);
    const $btn = $btns.find('a');

    const $tar = $('[data-tab-id="' + $btns.attr('data-tab-target') + '"]');
    const $tarCon = $tar.find('.p-tab__con');

    $btn.each(function(index, el) {
      const t = $(this);
      const i = index;

      if(t.hasClass('is-cr')) {
        $tarCon.eq(i).stop().fadeIn();
      }

      t.on('click', function(event) {
        $btn.removeClass('is-cr');
        t.addClass('is-cr');

        $tarCon.stop().hide();
        $tarCon.eq(i).stop().fadeIn();
      });
    });
  });
});

$(function(){
  $('.js-datepicker').each(function(index, el) {
    $.datepicker.setDefaults( $.datepicker.regional[ "ja" ] );
    $(this).datepicker({
    });
  });
});


$(function(){
  const $detail = $('.page-condi__searchDetail');

  if($detail[0]) {
    const $btn1 = $('.page-condi__btn1 a');
    const $btn2 = $('.page-condi__btn2 a, .page-condi__btn2-2 a');
    const $input = $('.page-condi input');
    let hash = location.hash;

    $btn1.on('click', function(event) {
      $btn1.toggleClass('is-on');
      $detail.stop().slideToggle();
    });

    if(hash == '#detail') {
      $btn1.addClass('is-on');
      $detail.stop().slideDown();
    }

    $btn2.addClass('is-disabled');

    $input.on('change', function(event) {
      $btn2.removeClass('is-disabled');
    });
  }
});
